﻿import * as $ from "jquery";
import "bootstrap/js/dist/modal";
import "../css/modules/tu.m/youtubePreview.css";
import tumGlobal from "./global";
import { addEventDelegate, toggleClass } from "./global/dom";

function pauseYoutubeVideos() {
	let videos = document.querySelectorAll('.youtube-iframe');
	for (let video of videos) {
		video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
	}
}

let youtubePreview = {
	options: {
	},
	init: function (options) {
		Object.assign(this.options, options);

		addEventDelegate(document, 'click', '.youtube-preview', element => {
			this.enableYoutubeVideo(element);
		});

		$(document).on('hide.bs.modal', '#yt-preview-modal', () => {
			document.querySelector('#yt-preview-modal .modal-body').innerHTML = "";
		});
	},
	enableYoutubeVideo: function(preview){
		let showAsModal = preview.matches('[data-modal]');

		let options = {
			width: preview.getAttribute('data-width'),
			height: preview.getAttribute('data-height'),
			url: preview.getAttribute('data-url'),
			class: preview.getAttribute('data-class')
		};

		if (showAsModal) {
			this.showAsModal(options);

			setTimeout(() => { 
				let overlay = document.querySelector('#yt-preview-modal .youtube-embed-overlay');
				toggleClass(overlay, 'show', true);
				setTimeout(() => toggleClass(overlay, 'show', false), 3000);
			}, 1000);

			return;
		}

		toggleClass(preview, 'youtube-preview', false);
		preview.innerHTML = this.renderVideo(options);
		setTimeout(() => { 
			toggleClass(preview.querySelector('.youtube-embed-overlay'), 'show', true);
			setTimeout(() => toggleClass(preview.querySelector('.youtube-embed-overlay'), 'show', false), 3000);
		}, 1000);

		preview.dispatchEvent(new Event("tu.ytpreview.rendered"));
	},
	renderVideo: function (options) {
		return `
			<div class="youtube-embed w-100 h-100">
			<div class="youtube-embed-overlay"><b>ВКЛЮЧИТЬ</b></div>
			<iframe width="${options.width || 320}" 
					height="${options.height || 180}" 
					src="${options.url}?enablejsapi=1" 
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen="1"
					class="h-100 w-100 ${options.class || ""} youtube-iframe"></iframe>
			</div>`;
	},
	_showAsModal: function(options) {
		let modal = document.querySelector('#yt-preview-modal');
		modal.querySelector('.modal-body').innerHTML = this.renderVideo(options);
		$(modal).modal('show');
	},
	showAsModal: function(options) {
		if (!document.querySelector('#yt-preview-modal')) {
			return tumGlobal.createModal({
				id: 'yt-preview-modal',
				noHeader: true,
				noFooter: true,
				useCloseButton: true,
				noBodyPadding: true,
				backdropStatic: true
			}).then(() => this._showAsModal(options));
		}

		this._showAsModal(options);
	},
	initPreview: function (container) {
		let url = container.getAttribute('data-url');
		let title = container.getAttribute('data-title');
		let width = container.getAttribute('data-width') || 320;
		let height = container.getAttribute('data-height') || 180;
		let innerHTML  = `<img src="https://img.youtube.com/vi/${this.urlToId(url)}/mqdefault.jpg" width="${width}" height="${height}" alt="YouTube">
						  <div class="youtube-preview-title">
						  	<img src="https://tu.market/Content/images/tu.m/logo_big/def/logo.png" width="40" height="40" alt="">
							<span class="text-truncate mr-3">${title}</span>
						  </div>
						  <div class="youtube-preview-icon-overlay"><i class="youtube-preview-icon fab fa-youtube"></i></div>`;

		container.innerHTML = innerHTML;
	},
	initPreviews: function(container){
		(container || document).querySelectorAll('.youtube-preview').forEach(e => {
			this.initPreview(e);
		});
	},
	urlToId: function (url) {
		let res = url.replace('https://www.youtube.com/embed/', '');
		res = res.indexOf('?') > -1 ? res.substring(0, url.indexOf('?')) : res;
		return res;
	}
};

export { youtubePreview, pauseYoutubeVideos };